var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nk-sidebar nk-sidebar-fixed sidebar-open",staticStyle:{"z-index":"999"}},[_c('div',{staticClass:"nk-sidebar-element nk-sidebar-head px-0 py-1"},[_c('div',{staticClass:"nk-sidebar-brand"},[_c('a',{staticClass:"logo-link nk-sidebar-logo",attrs:{"href":"javascript:void(0)"}},[_c('img',{staticClass:"logo-light logo-img img-fluid",attrs:{"src":`${_vm.publicPath}img/logo-mini.jpg`,"alt":"logo"}}),_c('img',{staticClass:"logo-dark logo-img img-fluid",attrs:{"src":`${_vm.publicPath}img/logo.jpg`,"alt":"logo-dark"}})])])]),_c('div',{staticClass:"nk-sidebar-element"},[_c('div',{staticClass:"nk-sidebar-content"},[_c('div',{staticClass:"nk-sidebar-menu",attrs:{"data-simplebar":"init"}},[_c('div',{staticClass:"simplebar-wrapper",staticStyle:{"margin":"-16px 0px -40px"}},[_vm._m(0),_c('div',{staticClass:"simplebar-mask"},[_c('div',{staticClass:"simplebar-offset",staticStyle:{"right":"0px","bottom":"0px"}},[_c('div',{staticClass:"simplebar-content-wrapper",staticStyle:{"height":"100%","overflow":"hidden scroll"}},[_c('div',{staticClass:"simplebar-content",staticStyle:{"padding":"16px 0px 40px"}},[(_vm.$helper.empty(_vm.loggedUser.branch))?_c('ul',{staticClass:"nk-menu"},[_vm._l((_vm.menus),function(menu){return [(
                          !_vm.$helper.empty(menu.submenus) &&
                          _vm.isMenuAccessible(
                            _vm.$helper.createTitleSlug(menu.title)
                          )
                        )?_c('li',{key:menu.title,staticClass:"nk-menu-item has-sub"},[_c('a',{staticClass:"nk-menu-link nk-menu-toggle",attrs:{"href":"javascript:void(0)","id":menu.title},on:{"click":function($event){$event.preventDefault();return _vm.showSubMenus(menu.title)}}},[_c('span',{staticClass:"nk-menu-icon"},[_c('em',{class:`icon ${menu.icon}`})]),_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v(_vm._s(_vm._f("trans")(_vm.$helper.capitalizify(menu.title))))]),(_vm.hasNotifications(menu.notifications))?_c('span',{staticClass:"nk-menu-badge"},[_vm._v(_vm._s(_vm.totalNotifications(menu.notifications)))]):_vm._e()]),_c('ul',{staticClass:"nk-menu-sub"},[_vm._l((menu.submenus),function(item,i){return [(
                                _vm.canView(
                                  [
                                    _vm.$helper.createTitleSlug(menu.title),
                                    'submenus',
                                    i,
                                  ].join('.')
                                )
                              )?_c('li',{key:i,staticClass:"nk-menu-item"},[_c('router-link',{staticClass:"nk-menu-link",attrs:{"to":item}},[_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v(_vm._s(_vm._f("trans")(_vm.$helper.inputTitle(i))))])])],1):_vm._e()]})],2)]):(
                          _vm.isMenuAccessible(
                            _vm.$helper.createTitleSlug(menu.title)
                          )
                        )?_c('li',{key:menu.title,staticClass:"nk-menu-item"},[_c('router-link',{staticClass:"nk-menu-link single-menu-item",attrs:{"to":menu.url}},[_c('span',{staticClass:"nk-menu-icon"},[_c('em',{class:`icon ni ${menu.icon}`})]),_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v(_vm._s(_vm._f("trans")(menu.title)))])])],1):_vm._e()]}),(_vm.isMenuAccessible('front_office'))?_c('li',{staticClass:"nk-menu-item"},[_c('a',{staticClass:"nk-menu-link single-menu-item",attrs:{"href":`https://sales.tameaps.com/?url=${
                          _vm.selfLocation + _vm.baseURL
                        }&token=${_vm.currentToken}`,"target":"_blank"}},[_vm._m(1),_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v("Front Office")])])]):_vm._e()],2):_c('ul',{staticClass:"nk-menu"},[_vm._l((_vm.branchMenus),function(menu){return [(
                          !menu.submenus && _vm.canAccessBranchMenu(menu.title)
                        )?_c('li',{key:menu.title,staticClass:"nk-menu-item"},[_c('router-link',{staticClass:"nk-menu-link",attrs:{"to":menu.url}},[_c('span',{staticClass:"nk-menu-icon",domProps:{"innerHTML":_vm._s(menu.icon)}}),_c('span',{staticClass:"nk-menu-text"},[_vm._v(_vm._s(menu.alias))])])],1):(_vm.canAccessBranchMenu(menu.title))?_c('li',{key:menu.title,staticClass:"nk-menu-item has-sub"},[_c('a',{staticClass:"nk-menu-link nk-menu-toggle",attrs:{"href":"javascript:void(0)","id":menu.title},on:{"click":function($event){$event.preventDefault();return _vm.showSubMenus(menu.title)}}},[_c('span',{staticClass:"nk-menu-icon",domProps:{"innerHTML":_vm._s(menu.icon)}}),_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v(_vm._s(menu.alias))])]),_c('ul',{staticClass:"nk-menu-sub"},[_vm._l((menu.submenus),function(item,i){return [(_vm.canAccessBranchMenu(menu.title, i))?_c('li',{key:i,staticClass:"nk-menu-item"},[_c('router-link',{staticClass:"nk-menu-link",attrs:{"to":item}},[_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v(_vm._s(_vm._f("trans")(_vm.$helper.inputTitle(i))))])])],1):_vm._e()]})],2)]):_vm._e()]}),_c('li',{staticClass:"nk-menu-item"},[_c('a',{staticClass:"nk-menu-link single-menu-item",attrs:{"href":`https://sales.tameaps.com/?url=${
                          _vm.selfLocation + _vm.baseURL
                        }&token=${_vm.currentToken}`,"target":"_blank"}},[_vm._m(2),_c('span',{staticClass:"nk-menu-text text-capitalize"},[_vm._v("Front Office")])])])],2)])])])]),_c('div',{staticClass:"simplebar-placeholder",staticStyle:{"width":"auto","height":"585px"}})]),_vm._m(3),_vm._m(4)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simplebar-height-auto-observer-wrapper"},[_c('div',{staticClass:"simplebar-height-auto-observer"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nk-menu-icon"},[_c('em',{staticClass:"icon ni ni ni-cart"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nk-menu-icon"},[_c('em',{staticClass:"icon ni ni ni-cart"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simplebar-track simplebar-horizontal",staticStyle:{"visibility":"hidden"}},[_c('div',{staticClass:"simplebar-scrollbar",staticStyle:{"width":"0px","display":"none"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simplebar-track simplebar-vertical",staticStyle:{"visibility":"visible"}},[_c('div',{staticClass:"simplebar-scrollbar",staticStyle:{"height":"81px","transform":"translate3d(0px, 138px, 0px)","display":"block"}})])
}]

export { render, staticRenderFns }